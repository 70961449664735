import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/layout"
import MetaCards from "../../components/common/meta-cards"
import Seo from "../../components/common/seo"
import Hero from "../../components/article/hero"
import FeaturedSection from "../../components/article/feature"
import ArticleContent from "../../components/article/content"
import OfferedServicesSurgeries from "../../components/shared/offered_services_surgeries"
import HelpfulLinks from "../../components/shared/helpful_links"
import Testimonials from "../../components/shared/testimonials"
import FAQs from "../../components/article/faqShort"
import TipsFromTeam from "../../components/shared/tips_from_team"
import Visit from "../../components/shared/visit"

const StandardPage = props => {
  // useEffect(() => {
  //   if (window.location.href.indexOf("surgery-overview") > -1) {
  //     document.querySelector(
  //       ".fullWidthWeOffer .row"
  //     ).innerHTML += `<a class="btn d-btn" href="/surgeries/">View All Surgeries</a>`
  //   }
  // }, [])

  return (
    <Layout>
      <Seo title={props.data.articles.title} />
      {/* <MetaCards data={props.data.articles.webMeta}/> */}
      <div className="servicesPage">
        <Hero articles={props.data.articles} />
        <FeaturedSection articles={props.data.articles} />
        {props.data.articles.content.map(item => (
          <ArticleContent key={item.id} item={item} />
        ))}
        <div
          className={props.data.articles.hideOfferedServicesLinks && "hideFAQ"}
        >
          <OfferedServicesSurgeries
            offer={props.data.articles.surgeriesServicesWeOffer}
          />
        </div>
        <div className={props.data.articles.hidePatientResources && "hideFAQ"}>
          <HelpfulLinks data={props.data.articles.clientResources} />
        </div>
        <Testimonials data={props.data.articles} about={true} />
        <div className={props.data.articles.hideFaQs && "hideFAQ"}>
          {props.data.articles.hideFaQs}
          <FAQs articles={props.data.articles} />
        </div>
        <TipsFromTeam data={props.data.articles.tipsFromTeam} services={true} />
        <Visit data={props.data.articles.scheduleAVisit} />
      </div>
    </Layout>
  )
}

export default StandardPage

export const query = graphql`
  query ($slug: String!) {
    articles: contentfulServicesSurgeriesArticles(slug: { eq: $slug }) {
      id
      image {
        file {
          url
        }
      }
      category
      title
      slug
      excerpt {
        excerpt
      }
      date
      keywords
      introText {
        raw
      }
      rightSideBar {
        title
        links {
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      newPatients {
        image {
          file {
            url
          }
        }
        title
        content {
          content
        }
        linkLabel
        linkUrl
      }
      content {
        id
        fullWidth
        imageToLeft

        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              description
            }
          }
        }
      }
      hideOfferedServicesLinks
      surgeriesServicesWeOffer {
        title
        excerpt {
          excerpt
        }
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        servicesSurgeriesLinks {
          id
          label
          url
          excerpt {
            excerpt
          }
        }
        weOfferImage
      }
      hidePatientResources
      clientResources {
        id
        image {
          file {
            url
          }
        }
        title
        subTitle
        links {
          __typename
          ... on ContentfulLinkTemplate {
            id
            label
            url
          }
        }
      }
      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }

      hideFaQs
      faqs {
        title
        content {
          content
        }
        faqs {
          id
          question
            theAnswer {
            raw
          }
        }
      }
      tipsFromTeam {
        title
        content
        tips {
          id
          label
          shortDescription
          url
          image {
            file {
              url
            }
          }
        }
      }

      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
      }
    }
  }
`
