import React from "react"

const HelpfulLinks = ({ data, surgery }) => {
  return (
    <section className="additional-info-sec surgery-add-sec">
      <div className="container">
        <div className="row">
          {data.map((item, i) => {
            if (i % 2 === 0) {
              return (
                <>
                  <div className="col col-md-6">
                    <div className="additional-info-box additional-info-box1 d-flex align-items-center">
                      <div>
                        <h4>{item.title}</h4>
                        <h2>{item.subTitle}</h2>
                        {/* {surgery && <p>{item.content.content}</p>} */}
                        {item.links.map(link => (
                          <div key={link.id}>
                            <a href={link.url} className="link-btn">
                              {link.label}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.243"
                                height="13.501"
                                viewBox="0 0 20.243 13.501"
                              >
                                <path
                                  id="Icon_ionic-ios-arrow-round-forward"
                                  data-name="Icon ionic-ios-arrow-round-forward"
                                  d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                                  transform="translate(-7.875 -11.252)"
                                  fill="#ff0245"
                                />
                              </svg>
                            </a>
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-6">
                    <img src={item.image.file.url} />
                  </div>
                </>
              )
            }
          })}
        </div>
        <div className="row">
          {data.map((item, i) => {
            if (i % 2 !== 0) {
              return (
                <>
                  <div className="col col-md-6 img-bg">
                    <img src={item.image.file.url} />
                  </div>
                  <div className="col col-md-6">
                    <div className="additional-info-box additional-info-box2 d-flex align-items-center">
                      <div>
                        <h4>{item.title}</h4>
                        <h2>{item.subTitle}</h2>
                        {/* {surgery && <p>{item.content.content}</p>} */}
                        {item.links.map(link => (
                          <div key={link.id}>
                            <a href={link.url} className="link-btn">
                              {link.label}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.243"
                                height="13.501"
                                viewBox="0 0 20.243 13.501"
                              >
                                <path
                                  id="Icon_ionic-ios-arrow-round-forward"
                                  data-name="Icon ionic-ios-arrow-round-forward"
                                  d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                                  transform="translate(-7.875 -11.252)"
                                  fill="#ff0245"
                                />
                              </svg>
                            </a>
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default HelpfulLinks
