import React from "react"
import { Link } from "gatsby"

const OfferedServicesSurgeries = ({ offer, surgery }) => {
  return (
    <section className={`service-surgery-sec ${offer.weOfferImage ? "fullWidthWeOffer" : " "
      }`}>
    {/* <section clasNames="service-surgery-sec "> */}
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>{offer.title}</h2>
            <p>{offer.excerpt.excerpt}</p>
          </div>
          <div class="col col-md-6 service-surgery-img">
            <div class="service-surgery-img1">
              <img src={offer.image1.file.url} alt="#" />
            </div>
            <div class="service-surgery-img2">
              <img src={offer.image2.file.url} alt="#" />
            </div>
          </div>
          <div class="col col-md-6 service-surgery-content">
            {offer.servicesSurgeriesLinks.map(item => (
              <div key={item.id}>
                {/* <Link to={item.url} class="link-btn"> */}
                <Link to={item.url} class="" style={{ fontWeight: '600', margin: '0 0 1rem', color: '#ff1d59', borderBottom: '2px solid #ff1d59', width: 'fit-content', textDecoration: 'none'}}>
                  {item.label}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.243"
                    height="13.501"
                    viewBox="0 0 20.243 13.501"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-round-forward"
                      data-name="Icon ionic-ios-arrow-round-forward"
                      d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                      transform="translate(-7.875 -11.252)"
                      fill="#ff0245"
                    />
                  </svg> */}
                </Link>
                <p>{item.excerpt.excerpt}</p>
              </div>
            ))}
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default OfferedServicesSurgeries
