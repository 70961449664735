import React, {useRef, useEffect} from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

const Testimonials = ({ data, about }) => {

const slider = React.useRef(null);

  var TestimonialSlick = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <><div className="testimonial-slider-dots"><ul>{dots}</ul></div></>,
    arrows: false,
    // nextArrow: <SlickArrowRight />,
    // appendArrows: $('.testimonial-slider-nav'),
    // appendDots: $('.testimonial-slider-dots'),
    // prevArrow: "<button class='slick-prev'><img src='./assets/images/arrow-l.svg' alt='#' /></button>",
    // nextArrow: "<button class='slick-next'>Submit Your Experience with Us <img src='./assets/images/arrow-r.svg' alt='#' /></button>",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    if (document.querySelector(".slick-track").childNodes.length == 1) { // Or just `if (element.childNodes.length)`
    // It has at least one
      document.querySelector(".nextSlickBtn").classList.add("singleSlickBtn");
  }
  }, []);
  
  return (
    <section className={`testimonial-sec ${about && 'surgery-testimonial-sec'}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{data.testimonialTitle}</h2>
          </div>
          <div className="col testimonial-slider">
          <Slider {...TestimonialSlick} ref={slider}>
            {data.testimonials.map(item => (
                  <div className="testimonial-box" key={item.id}>
                    <p>{item.testimonial.testimonial}</p>
                    <h6>{item.client}</h6>
                </div>
            ))}
            </Slider>            
          </div>
          <Link to={'/testimonials?share-story'} className="nextSlickBtn"> 
            Submit Your Experience with Us 
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.243"
              height="10.501"
              viewBox="0 0 20.243 13.501"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                transform="translate(-7.875 -11.252)"
                fill="#151515"
              />
          </svg>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
